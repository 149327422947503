import {
  title,
  mrAuto,
  mlAuto,
  grayColor
} from "assets/jss/material-kit-pro-react.js";

const workStyle = {
  mrAuto,
  mlAuto,
  section: {
    padding: "70px 0"
  },
  title: {
    ...title,
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
    color: '#2f512c !important',
    // borderTop: '3px solid #cca64a',
    // borderBottom: '3px solid #cca64a', 
    marginBottom: 50 ,
    letterSpacing: '4px !important',
    textTransform: 'uppercase' 
  },
  description: {
    color: grayColor[0],
    textAlign: "center"
  },
  textCenter: {
    textAlign: "center"
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  },
  
};

export default workStyle;
