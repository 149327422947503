/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

import { FormHelperText, Snackbar } from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import MaskedInput from "react-text-mask";

import { postRequestWithOutToken } from '../../services/signUp';

import image from "../../assets/img/img1.jpeg";

import { Formik } from 'formik';
import * as Yup from 'yup';
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";

const useStyles = makeStyles(signupPageStyle);

export default function SignUpPage(props) {
  const [email, setEmail] = React.useState('');
  const [fullname, setFullname] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [signUpAlert, setSignUpAlert] = React.useState('');
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  // const handleToggle = value => {
  //   const currentIndex = checked.indexOf(value);
  //   const newChecked = [...checked];
  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }
  //   setChecked(newChecked);
  // };



  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  function handleCloseSnackbar() {
    setOpenSnackBar(false)
  }

  const classes = useStyles();
  return (
    <div>
      <Header
        absolute
        color="transparent"
        links={<HeaderLinks dropdownHoverColor="rose" />}
      />
      <div
        // className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: '100vh',
          overflowY: 'auto'
        }}>
        <div className={classes.container} style={{ paddingTop: '22vh' }}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={10} md={5}>

              <Card className={classes.cardSignup}>
                <div className={classes.divWrap}>
                  <div className={classes.divLine}>
                    <h3 className={classes.cardTitle}>Sign Up</h3>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  {/* <img src={logo} alt="img" style={{ height: 150, width: 200 }} /> */}
                </div>
                <CardBody style={{ paddingTop: 100 }}>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={8} md={12}>

                      <Formik
                        enableReinitialize={true}
                        validationSchema={Yup.object().shape({
                          email: Yup.string()
                            .email('Email is invalid'),
                          fullname: Yup.string()
                            .required('Name is required'),
                          phone: Yup.string()
                            .required('Phone number is required'),
                        })}
                        initialValues={{
                          email: email,
                          fullname: fullname,
                          phone: phone,
                        }}
                        onSubmit={(values) => {
                          postRequestWithOutToken('/addcandidate', values).then((res) => {
                            if (res.error) {
                              postRequestWithOutToken('/event', { 'event': 'click', value: 'candidate clicked sign up button but got error' });
                              setOpenSnackBar(true)
                              setSignUpAlert(res.error.text)
                            } else {
                              postRequestWithOutToken('/event', { 'event': 'click', value: 'candidate clicked sign up button and it was success' });
                              localStorage.setItem('id', res.candidateData.id)
                              localStorage.setItem('token', res.candidateData.token)
                              props.history.push('/question', { data: res })
                            }
                          }).catch((err) => {
                            console.log(err);
                          })
                        }
                        }>
                        {({ errors, values, touched, handleSubmit, handleChange }) => (
                          <>
                            <Snackbar className="snackbar" message={signUpAlert} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackBar} onClose={handleCloseSnackbar} autoHideDuration={6000} />
                            <div className={classes.SignUpFormDiv1} >
                              <div style={{ marginBottom: 10 }}>
                                <TextField
                                  fullWidth
                                  label={'Full Name'}
                                  name="fullname"
                                  margin="normal"
                                  variant="outlined"
                                  value={values.fullname}
                                  onChange={handleChange}
                                />
                                {errors.fullname && touched.fullname && <FormHelperText error>{errors.fullname}</FormHelperText>}
                              </div>

                              <div style={{ marginBottom: 10 }}>
                                <TextField
                                  fullWidth
                                  label={'Email'}
                                  name="email"
                                  type="email"
                                  onChange={handleChange}
                                  autoComplete="email"
                                  margin="normal"
                                  variant="outlined"
                                  value={values.email}
                                />
                                {errors.email && touched.email && <FormHelperText error>{errors.email}</FormHelperText>}
                              </div>

                              <div style={{ marginBottom: 10 }}>
                                {/* <MuiPhoneNumber
                                  fullWidth
                                  name="phone"
                                  variant="outlined"
                                  disableAreaCodes={true}
                                  onBlur={handleChange}
                                  defaultCountry={'us'}
                                  countryCodeEditable={false}
                                  label={'Mobile Phone Number'}
                                  value={values.phone}
                                  onlyCountries={['us']}
                                />*/}
                                {/* <TextField
                                  fullWidth
                                  label={'Phone Number'}
                                  name="phone"
                                  margin="normal"
                                  variant="outlined"
                                  value={values.phone}
                                  onChange={handleChange}                                 
                                /> */}

                                <MaskedInput
                                  mask={[
                                    "+",
                                    "1",
                                    "(",
                                    /[1-9]/,
                                    /\d/,
                                    /\d/,
                                    ")",
                                    " ",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    "-",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/
                                  ]}
                                  className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl"
                                  placeholder="Enter phone number"
                                  guide={false}
                                  name="phone"
                                  onChange={handleChange}
                                  defaultValue={values.phone}
                                  autoComplete="off"
                                  style={{
                                    padding: 15,
                                    marginTop: 10
                                  }}
                                />
                                {errors.phone && touched.phone && <FormHelperText error>{errors.phone}</FormHelperText>}
                              </div>


                              <div className={classes.textCenter}>
                                <Button
                                  onClick={handleSubmit}
                                  className={classes.button}
                                  variant="contained"
                                  // size="medium"                                  
                                  style={{
                                    marginTop: "30px",
                                    backgroundColor: "#2f512c",
                                    color: 'white'
                                  }} >
                                  Sign Up
                                </Button>
                              </div>
                            </div>
                          </>
                        )}

                      </Formik>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        {/* <Footer
          content={
            <div>
              <div className={classes.right}
                style={{
                  color: '#607d8b !important'
                }}>
                &copy; {1900 + new Date().getYear()} , Made by{" "}
                <a href="/" style={{ cursor: "pointer" }}>
                  Texas Heritage Real Estate
                </a>
              </div>
            </div>
          }
        /> */}
      </div>
    </div>
  );
}
