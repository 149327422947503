/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionCards from "views/PresentationPage/Sections/SectionCards.js";
import Testimonials from "./Testimonials";
import SectionFreeDemo from "views/PresentationPage/Sections/SectionFreeDemo.js";


import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function AboutUsPage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    return (
        <div>
            <Header
                brand="Material Kit PRO React"
                links={<HeaderLinks dropdownHoverColor="info" />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 300,
                    color: "white"
                }}
            />
            <Parallax
                image={require("assets/img/img1.jpeg")}
                className={classes.parallax}
            />

            <div className={classNames(classes.main, classes.mainRaised)} style={{ paddingBottom: 50 }}>
                <div className={classes.container}>
                    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
                        <h2 className={classes.title}> About Us </h2>
                        <GridContainer>
                            <GridItem
                                md={10}
                                sm={8}
                                className={classNames(classes.mrAuto, classes.mlAuto)}
                            >
                                <h5 className={classes.description}>
                                Since long, the name Texas Heritage Real Estate has been synonymous with the best estates, high-profile clientele, and superior customer service in the Texas luxury real estate market.
                                </h5>
                                {/* <h5 className={classes.description}>
                                    With an individualised hormone replacement therapy and well being programme at Menergy. you will quickly experience a boost in body composition, body density/strength, libido, cognitive capacity. memory, mental energy, stamina, mood and well-being.
                                </h5>
                                <h5 className={classes.description}>
                                    The in-depth testing that is done at Menergy along with regular follow ups with our experienced patient care coordinators, is part of the secret in creating the shortest route to an improved quality of life.
                                </h5>
                                <h5 className={classes.description}>
                                    Wellness is our craft, we never stop looking after your health/we are transparent, ethical an passionate about what we do. We put you first and strive to provide the best care.
                                </h5> */}
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
                <div className={classes.sectionCards}>
                    <SectionCards />
                </div>
                <div>
                    <Testimonials />
                </div>
                <div>
                    <SectionFreeDemo />
                </div>
            </div>
            <Footer
                content={
                    <div>
                        <div className={classes.right}>
                            &copy; {1900 + new Date().getYear()} , Made By Menergy
                        </div>
                    </div>
                }
            />
        </div>
    );
}
