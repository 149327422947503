import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
// import success from "../../assets/img/success.svg";
import { Button } from '@material-ui/core';
import Header from 'components/Header/Header';
import HeaderLinks from 'components/Header/HeaderLinks';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
// import cardBlog3 from "assets/img/img4.jpg";
import Package from "../Packages/Package.js";

import { InitialTestKit } from "../Packages/Data.js";

const styles = ({
    divFlex: {
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '150px'
    },
    fontSize: {
        fontSize: '1.3rem',
        margin: '10px 0px',
        fontWeight: '600'
    },
    fontSize2: {
        fontSize: '1rem',
        margin: '10px 0px',
        fontWeight: '600'
    },
    link: {
        color: 'inherits',
        textDecoration: 'none',
    },
    footer: {
        height: '80px',
        background: '#164d5e',
        width: '100%',
        color: 'white',
        paddingTop: '20px',
        textAlign: 'center',
        position: 'absolute',
        bottom: '0',
        fontSize: '16px'
    },
    buttonCenter: {
        textAlign: 'center'
    },
    container: {
        paddingRight: "15px",
        paddingLeft: "15px",
        marginRight: "auto",
        marginLeft: "auto",
        width: "100%",
        "@media (min-width: 576px)": {
            maxWidth: "540px"
        },
        "@media (min-width: 768px)": {
            maxWidth: "720px"
        },
        "@media (min-width: 992px)": {
            maxWidth: "960px"
        },
        "@media (min-width: 1200px)": {
            maxWidth: "1140px"
        }
    },
    cardSignup: {
        borderRadius: "6px",
        boxShadow: '1px 0px 20px 9px #00000061',
        marginBottom: "100px",
        padding: "40px 0px",
        paddingTop: 0
    },
    divLine: {
        backgroundColor: '#2f512c',
        height: '100px',
        width: '85%',
        position: 'absolute',
        top: '-5%'
    },
    divWrap: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    cardTitle: {
        textDecoration: "none",
        textAlign: "center !important",
        marginBottom: "0.75rem",
        color: "white !important",
        lineHeight: '80px',
        fontFamily: 'inherit !important',
        margin: '10px 0',
        fontWeight: '500',
    },
    divResult: {
        textAlign: 'center',
        padding: 80
    },
    fontStyle: {
        fontSize: '1.2rem',
        fontWeight: '500'
    },
    result: {
        textAlign: 'center'
    },
    cardCategory: {
        fontWeight: 'bold',
        fontSize: '1.2rem'
    }
});

class Response extends Component {

    constructor(props) {
        super(props)

        this.state = {
            firstname: '',
            ksa: '52',
        };
        this.paramLanguage = this.props.location && this.props.location.state && this.props.location.state.lang;
    };

    async componentWillMount() {

        const firstName = await localStorage.getItem('firstName');
        const lastname = await localStorage.getItem('lastname');

        this.setState({
            firstName: firstName,
            lastname: lastname,
        }, () => {
            this.setState({ dataSet: true })
        })
    }

    logOut = () => {
        sessionStorage.clear();
        localStorage.clear();
    }

    goToProfile = () => {
        this.props.history.push('/profile', { lang: this.paramLanguage })
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    render() {
        const { classes } = this.props;
        const { ksa } = this.state;

        return (
            <div>
                <Header
                    absolute
                    color="transparent"
                    links={<HeaderLinks dropdownHoverColor="rose" />}
                />
                <div className={classes.divFlex}>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={10} md={8}>
                                <Card className={classes.cardSignup}>
                                    <div className={classes.divWrap}>
                                        <div className={classes.divLine}>
                                            <h3 className={classes.cardTitle}>Result</h3>
                                        </div>
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                    </div>
                                    <CardBody style={{ paddingTop: 100 }}>
                                        <GridContainer justify="center">
                                            <GridItem>
                                                {ksa > '50' ?
                                                    <div className={classes.result}>
                                                        <p className={classes.fontStyle}>
                                                            Your score is above 50%, you have testosterone deficiency.
                                                        </p>
                                                        <p className={classes.fontStyle}>
                                                            Please purchase Initial Test Kit
                                                        </p>
                                                        {/* <div> */}
                                                        <GridContainer justify="center">
                                                            <GridItem xs={12} sm={6} md={6}>
                                                                <Card pricing plain>
                                                                    <CardBody pricing plain>
                                                                        <h4 className={classes.cardCategory}>Initial Test Kit</h4>
                                                                        <hr />
                                                                        <h1>
                                                                            <small>&euro;</small> 65
                                                                        </h1>
                                                                        {InitialTestKit.map((val) =>
                                                                            <Package data={val.data} checked={val.checked} />
                                                                        )}
                                                                    </CardBody>
                                                                    <CardFooter pricing className={classes.justifyContentCenter}>
                                                                        <Button
                                                                            href="\payment"
                                                                            size="medium"
                                                                            variant="contained"
                                                                            style={{
                                                                                marginTop: "30px",
                                                                                backgroundColor: "#2f512c",
                                                                                color: 'white',
                                                                                padding: '10px 25px'
                                                                            }} >
                                                                            BUY
                                                                        </Button>
                                                                    </CardFooter>
                                                                </Card>
                                                            </GridItem>
                                                        </GridContainer>
                                                        {/* </div> */}
                                                    </div>
                                                    :
                                                    <div className={classes.divResult}>
                                                        <p className={classes.fontStyle}>
                                                            Your score is below 50%, you do not have testosterone deficiency.
                                                        </p>
                                                        <p className={classes.fontStyle}>
                                                            Thank you !
                                                        </p>
                                                    </div>
                                                }
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
                <div className={classes.buttonCenter}>
                    {/* <Button
                        variant="contained"
                        size="medium"
                        style={{
                            marginTop: "20px",
                            backgroundColor: "#3f51b5",
                            color: 'white'
                        }} >
                        Buy
                    </Button> */}
                </div>
            </div>
        );
    }
}

Response.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Response);