import {
  container,
  title,
  main,
  mainRaised,
  mrAuto,
  whiteColor,
  mlAuto,
  description
} from "assets/jss/material-kit-pro-react.js";

const aboutUsStyle = {
  main,
  mainRaised,
  mrAuto,
  mlAuto,
  container: {
    ...container,
    zIndex: 1
  },
  title: {
    ...title,
    textAlign: 'center',
    marginBottom: 50 ,
    marginTop: 0,
    borderBottom: '1px solid #d3d3d3',
    paddingBottom: 40 ,
    color: '#2f512c !important',
    // borderTop: '3px solid #cca64a',
    // borderBottom: '3px solid #cca64a', 
    marginBottom: 50 ,
    letterSpacing: '4px !important',
    textTransform: 'uppercase'  
  },
  textCenter: {
    textAlign: "center"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  aboutDescription: {
    padding: "50px 0 0 0"
  },
  description: {
    ...description,
    textAlign: 'justify'
  },
  sectionCards: {
    marginTop: 50
  }
};

export default aboutUsStyle;
