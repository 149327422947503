/*eslint-disable*/
import React from "react";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";
import "../PresentationPage/Presentation.scss";


export default function Package(props) {
    
    return (
        <div >
            <ul className="package">
                <li>
                    {props.checked ?
                        <Success>
                            <Check />
                        </Success>
                        :
                        <Danger>
                            <Close />
                        </Danger>
                    }
                    {props.data}
                    <br />
                    {props.additional}
                </li>
            </ul>
        </div>
    );
}
