import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button.js";
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import img8 from "assets/img/img8.jpg";
import img9 from "assets/img/img9.jpg";
import img10 from "assets/img/img10.jpg";
import img11 from "assets/img/img11.jpg";
import img12 from "assets/img/img12.jpg";
import img13 from "assets/img/img13.jpg";
import img15 from "assets/img/img15.jpg";
import img16 from "assets/img/img16.jpg";
import img14 from "assets/img/img14.jpg";
import '../Presentation.scss';


const useStyles = makeStyles(shoppingCartStyle);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function FullWidthTabs() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    function handleChangeIndex(index) {
        setValue(index);
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default" className={classes.appBar}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    classes={{
                        indicator: classes.indicator
                    }}
                >
                    <Tab label="Buy Home" {...a11yProps(0)} />
                    <Tab label="Rent a Home" {...a11yProps(1)} />
                    <Tab label="Sell a Home " {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <GridContainer>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card pricing raised>
                                <CardBody pricing >
                                    <img src={img8} className={classes.tabImage}/>
                                    <h4 className={classes.cardCategory}>Dallas</h4>
                                    <h5 className={classes.cardCategory} style={{ fontWeight: '700' }}>Price : $192,500</h5>
                                    <h6 className={classes.cardCategory} style={{ fontWeight: 'normal' }}>Description : 2bed 1bath 1,050sqft</h6>
                                </CardBody>
                                <CardFooter pricing className={classes.justifyContentCenter}>
                                    <Button color="secondary" round style={{ color: 'white' }}>
                                        BUY
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card pricing raised>
                                <CardBody pricing >
                                    <img src={img9} className={classes.tabImage}/>                                    
                                    <h4 className={classes.cardCategory}>Houston</h4>
                                    <h5 className={classes.cardCategory} style={{ fontWeight: '700' }}>price : $192,500</h5>
                                    <h6 className={classes.cardCategory} style={{ fontWeight: 'normal' }}>Description : 2bed 1bath 1,050sqft</h6>
                                </CardBody> 
                                <CardFooter pricing className={classes.justifyContentCenter}>
                                    <Button color="secondary" round style={{ color: 'white' }}>
                                        BUY
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card pricing raised>
                                <CardBody pricing >
                                    <img src={img10} className={classes.tabImage}/>                                                                        
                                    <h4 className={classes.cardCategory}>Austin</h4>
                                    <h5 className={classes.cardCategory} style={{ fontWeight: '700' }}>price : $192,500</h5>
                                    <h6 className={classes.cardCategory} style={{ fontWeight: 'normal' }}>Description : 2bed 1bath 1,050sqft</h6>
                                </CardBody>
                                <CardFooter pricing className={classes.justifyContentCenter}>
                                    <Button color="secondary" round style={{ color: 'white' }}>
                                        BUY
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </TabPanel>


                {/* <TabPanel value={value} index={1} dir={theme.direction}>
                    <GridContainer>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card pricing raised>
                                <CardBody pricing >
                                    <img src={img11} className={classes.tabImage}/>                                   
                                    <h4 className={classes.cardCategory}>Dallas</h4>
                                    <h5 className={classes.cardCategory} style={{ fontWeight: '700' }}>price : $192,500</h5>
                                    <h6 className={classes.cardCategory} style={{ fontWeight: 'normal' }}>Description : 2bed 1bath 1,050sqft</h6>
                                </CardBody>
                                <CardFooter pricing className={classes.justifyContentCenter}>
                                    <Button color="secondary" round style={{ color: 'white' }}>
                                        SELL
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card pricing raised>
                                <CardBody pricing >
                                    <img src={img12} className={classes.tabImage}/>                                                                       
                                    <h4 className={classes.cardCategory}>Houston</h4>
                                    <h5 className={classes.cardCategory} style={{ fontWeight: '700' }}>price : $192,500</h5>
                                    <h6 className={classes.cardCategory} style={{ fontWeight: 'normal' }}>Description : 2bed 1bath 1,050sqft</h6>
                                </CardBody>
                                <CardFooter pricing className={classes.justifyContentCenter}>
                                    <Button color="secondary" round style={{ color: 'white' }}>
                                        SELL
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card pricing raised>
                                <CardBody pricing >
                                    <img src={img13} className={classes.tabImage}/>                                                                      
                                    <h4 className={classes.cardCategory}>Austin</h4>
                                    <h5 className={classes.cardCategory} style={{ fontWeight: '700' }}>price : $192,500</h5>
                                    <h6 className={classes.cardCategory} style={{ fontWeight: 'normal' }}>Description : 2bed 1bath 1,050sqft</h6>
                                </CardBody>
                                <CardFooter pricing className={classes.justifyContentCenter}>
                                    <Button color="secondary" round style={{ color: 'white' }}>
                                        SELL
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>

                    </GridContainer>
                </TabPanel> */}


                <TabPanel value={value} index={1} dir={theme.direction}>
                    <GridContainer>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card pricing raised>
                                <CardBody pricing >
                                    <img src={img14} className={classes.tabImage}/>                                                                      
                                    <h4 className={classes.cardCategory}>Dallas</h4>
                                    <h5 className={classes.cardCategory} style={{ fontWeight: '700' }}>price : $192,500</h5>
                                    <h6 className={classes.cardCategory} style={{ fontWeight: 'normal' }}>Description : 2bed 1bath 1,050sqft</h6>
                                </CardBody>
                                <CardFooter pricing className={classes.justifyContentCenter}>
                                    <Button color="secondary" round style={{ color: 'white' }}>
                                        BUY
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card pricing raised>
                                <CardBody pricing >
                                    <img src={img15} className={classes.tabImage}/>                                                                       
                                    <h4 className={classes.cardCategory}>Houston</h4>
                                    <h5 className={classes.cardCategory} style={{ fontWeight: '700' }}>price : $192,500</h5>
                                    <h6 className={classes.cardCategory} style={{ fontWeight: 'normal' }}>Description : 2bed 1bath 1,050sqft</h6>
                                </CardBody>
                                <CardFooter pricing className={classes.justifyContentCenter}>
                                    <Button color="secondary" round style={{ color: 'white' }}>
                                        BUY
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card pricing raised>
                                <CardBody pricing >
                                    <img src={img16} className={classes.tabImage}/>                                                                       
                                    <h4 className={classes.cardCategory}>Austin</h4>
                                    <h5 className={classes.cardCategory} style={{ fontWeight: '700' }}>price : $192,500</h5>
                                    <h6 className={classes.cardCategory} style={{ fontWeight: 'normal' }}>Description : 2bed 1bath 1,050sqft</h6>
                                </CardBody>
                                <CardFooter pricing className={classes.justifyContentCenter}>
                                    <Button color="secondary" round style={{ color: 'white' }}>
                                        BUY
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </TabPanel>
            </SwipeableViews>
        </div>
    );
}