import  { appConfig } from './env';

var apiBaseUri = appConfig.apiBaseUri;
// const apiEndPoint = apiBaseUri
const api = apiBaseUri


export function GetBaseApiEndPoint() {
    return api;
}
