import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

// @material-ui icons
// import Apps from "@material-ui/icons/Apps";
// import ViewDay from "@material-ui/icons/ViewDay";
// import ViewCarousel from "@material-ui/icons/ViewCarousel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


import img5 from "assets/img/img5.jpg";
import img6 from "assets/img/img6.jpg";
import img7 from "assets/img/img7.jpg";
import img8 from "assets/img/img8.jpg";
import img9 from "assets/img/img9.jpg";
import img10 from "assets/img/img10.jpg";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classes.section} style={{ paddingTop: '30px' }}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={8} sm={8}>
            <h4 className={classes.description}>
              "Since long, the name Texas Heritage Real Estate has been synonymous with the best estates, high-profile clientele, and superior customer service in the Texas luxury real estate market."
            </h4>
          </GridItem>
        </GridContainer>
        <div className={classes.features}>
          <h2 className={classes.title}> New Listings by Area </h2>
          <GridContainer>
            <GridItem md={4} sm={4}>
              <div style={{ boxShadow: '1px 1px 8px 0px #d3d3d3', marginRight: 10, marginBottom: 40, height: '24rem', borderBottom: '2px solid #f41212' }}>
              <InfoArea
                title="Dallas"
                description="Dallas, a modern metropolis in north Texas, is a commercial and cultural hub of the region."
                icon={img5}
                iconColor="rose"
                vertical={true}
                isDesc={true}
              />
              </div>
            </GridItem>
            <GridItem md={4} sm={4}>
              <div
              style={{ boxShadow: '1px 1px 8px 0px #d3d3d3', marginRight: 10, marginBottom: 40, height: '24rem', borderBottom: '2px solid #f41212' }}>
              <InfoArea
                title="Austin"
                description="Austin is the state capital of Texas, an inland city bordering the Hill Country region."
                iconColor="rose"
                icon={img6}
                vertical={true}
                style={{ boxShadow: '1px 1px 8px 0px #d3d3d3', marginRight: 10, marginBottom: 40, height: '24rem', borderBottom: '2px solid #f41212' }}
                isDesc={true}
              />
              </div>
            </GridItem>
            <GridItem md={4} sm={4}>
              <div 
              style={{ boxShadow: '1px 1px 8px 0px #d3d3d3', marginRight: 10, marginBottom: 40, height: '24rem', borderBottom: '2px solid #f41212' }}>
              <InfoArea
                title="Houston"
                description="Houston is a large metropolis in Texas, extending to Galveston Bay."
                icon={img7}
                iconColor="rose"
                vertical={true}
                isDesc={true}
              />
              </div>
            </GridItem>
            <GridItem md={4} sm={4}>
              <div 
              style={{ boxShadow: '1px 1px 8px 0px #d3d3d3', marginRight: 10, marginBottom: 40, height: '24rem', borderBottom: '2px solid #f41212' }}>
              <InfoArea
                title="Frisco"
                description="Frisco is a city in Texas and part of the Dallas-Fort Worth metro area."
                icon={img8}
                iconColor="rose"
                vertical={true}
                isDesc={true}
              />
              </div>
            </GridItem>
            <GridItem md={4} sm={4}>
              <div 
              style={{ boxShadow: '1px 1px 8px 0px #d3d3d3', marginRight: 10, marginBottom: 40, height: '24rem', borderBottom: '2px solid #f41212' }}>
              <InfoArea
                title="Denton"
                description="Denton is a city in Texas within the Dallas-Fort Worth metro area."
                icon={img9}
                iconColor="rose"
                vertical={true}
                isDesc={true}
              />
              </div>
            </GridItem>
            <GridItem md={4} sm={4}>
              <div 
              style={{ boxShadow: '1px 1px 8px 0px #d3d3d3', marginRight: 10, marginBottom: 40, height: '24rem', borderBottom: '2px solid #f41212' }}>
              <InfoArea
                title="Waco"
                description="The Waco siege was the siege of a compound belonging to the religious sect Branch Davidians."
                icon={img10}
                iconColor="rose"
                vertical={true}
                isDesc={true}
              />
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
