import { GetBaseApiEndPoint } from './../appConfig';
const apibase = GetBaseApiEndPoint()

export function questionDetails(candidate_data) {
    return new Promise((resolve, reject) => {
        fetch(apibase + '/candidatequestions/'+ candidate_data.candidate_id, {
            method: 'POST',
            body: JSON.stringify(candidate_data)
        })
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function questionAnswerApi(updateData,candidate_data) {
    
    return new Promise((resolve, reject) => {
        fetch(apibase + '/addquestionnaire/'+ candidate_data.candidate_id, {
            method: 'POST',
            body: JSON.stringify(updateData)
        })
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}