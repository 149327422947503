
import { GetBaseApiEndPoint } from './../appConfig';
const BASE_URL = GetBaseApiEndPoint()

export async function postRequestWithOutToken(url, data) {
	return new Promise((resolve, reject) => {
		fetch(`${BASE_URL}${url}`, {
			method: 'POST',
			body: JSON.stringify(data)
		})
		.then((response) => response.json())
		.then((res) => {
			resolve(res);
		})
		.catch((error) => {
			reject(error);
		});
	});
}
