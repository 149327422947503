/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Edit from "@material-ui/icons/Edit";

import Button from "components/CustomButtons/Button.js";

import styles from "../../assets/jss/material-kit-pro-react/components/headerLinksStyle";
import { ListItemText } from "@material-ui/core";


const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto + " " + classes.headerLink}>
      <ListItem className={classes.listItem}>
        <Link to="/about-us" className={classes.dropdownLink}>
          <ListItemText style={{ marginRight: '15px' }}> About Us </ListItemText>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/" className={classes.dropdownLink}>
          <ListItemText style={{ marginRight: '15px', fontWeight: 'bold' }}> Property Search </ListItemText>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/" className={classes.dropdownLink}>
          <ListItemText style={{ marginRight: '15px' }}> Agents </ListItemText>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/sign-in" className={classes.dropdownLink}>
          <ListItemText style={{ marginRight: '15px' }}> Sign In </ListItemText>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/signup" className={classes.dropdownLink}>
          <ListItemText style={{ marginRight: '15px' }}> Sign Up </ListItemText>
        </Link>
      </ListItem>
      
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "black"
  ])
};
