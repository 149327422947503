/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
import SectionCards from "views/PresentationPage/Sections/SectionCards.js";
import SectionFreeDemo from "views/PresentationPage/Sections/SectionFreeDemo.js";
import SectionFeatures from "views/SectionsPage/Sections/SectionFeatures";
import SectionTestimonials from "views/SectionsPage/Sections/SectionTestimonials";
import PackageTabs from "./Sections/Tabs";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

// import NavPills from "components/NavPills/NavPills";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";


const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/img1.jpeg")}
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionDescription />
        <SectionCards />
        <div className="cd-section" style={{ padding: '70px 0' }}>

          {/* <div className={classes.pricing}> */}
          <div className={classes.container}>
            <GridContainer style={{ justifyContent: 'center' }}>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={
                  classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                }
              >
                <h2 className={classes.title} style={{ color: 'inherit', textAlign: 'center' }}>Pick the best deal for you</h2>

                <PackageTabs />

              </GridItem>
            </GridContainer>
          </div>
          {/* </div> */}
        </div>
        <SectionFeatures />


        <SectionTestimonials />

        <SectionFreeDemo />
      </div>
      <Footer
        content={
          <div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , Made By Texas Heritage Real Estate{" "}
            </div>
          </div>
        }
      />
    </div>
  );
}
