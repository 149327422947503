import React from "react";
// nodejs library that concatenates classes
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";

// import cardsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/cardsStyle.js";

// import bg from "assets/img/examples/card-project3.jpg";
import "../Presentation.scss"
import { Button } from "@material-ui/core";

// const useStyles = makeStyles(cardsStyle);

export default function SectionCards() {
  return (
    <div className="sectionStyle"
      style={{ backgroundImage: `url(${require("assets/img/img3.jpg")})` }}
    >
      <div className="divWidth">
        <GridContainer justify="center" spacing={3}>
          <GridItem md={5} sm={5}>
            <div className="divBgStyle">
              <h6 className="fontStyle">
              </h6>
              <h2 className="fontStyle mb-10"> Texas Heritage Real Estate </h2>
              <h5 className="blackColor">
                Since long, the name Texas Heritage Real Estate has been synonymous with the best estates, high-profile clientele, and superior customer service in the Texas luxury real estate market.
              </h5>
              <div style={{ width: '100%', textAlign: 'right' }}>
              <Button
                  href="/about-us"
                  variant="contained"
                  size="medium"                                  
                  style={{
                    marginTop: "30px",
                    backgroundColor: "#2f512c",
                    color: 'white'
                  }} >
                    About Us
                </Button>
                </div>
            </div>
          </GridItem>
          <GridItem md={7} sm={7}>

          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
