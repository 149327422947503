import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// // @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Apps from "@material-ui/icons/Apps";

import bg9 from "assets/img/img2.jpeg"

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>      
      <div
        className={classes.features5}
        style={{ backgroundImage: `url(${bg9})`, backgroundAttachment: 'fixed' }}
      >
        <GridContainer justify="center">
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <h2 className={classes.title}>Texas Property Snapshot</h2>
            <h4 style={{ color: 'white', position: 'relative', zIndex: '2' }}> Texas is the second largest state in the United States by area and population. Geographically located in the South Central region of the country, Texas shares borders with the U.S. states of Louisiana to the east, Arkansas to the northeast, Oklahoma to the north, New Mexico to the west, and the Mexican states of Chihuahua, Coahuila, Nuevo León, and Tamaulipas to the southwest, and has a coastline with the Gulf of Mexico to the southeast.</h4>
          </GridItem>
          <div className={classes.container}>
            <GridContainer className={classes.gridContainer} justify="center">
              <GridItem xs={12} sm={3} style={{ textAlign: 'center' }}>
                <h2 style={{ color: '#cca64a', fontWeight: 'bold' }}> 500+ </h2>
                <h4 style={{ color: 'white', position: 'relative', zIndex: '2' }}> Residential Projects in Texas </h4>
              </GridItem>
              <GridItem xs={12} sm={3} style={{ textAlign: 'center' }}>
                <h2 style={{ color: '#f41212', fontWeight: 'bold' }}> 450+ </h2>
                <h4 style={{ color: 'white', position: 'relative', zIndex: '2' }}> Residential Property Agents in Texas </h4>
              </GridItem>
              <GridItem xs={12} sm={3} style={{ textAlign: 'center' }}>
                <h2 style={{ color: '#cca64a', fontWeight: 'bold' }}> 325+ </h2>  
                <h4 style={{ color: 'white', position: 'relative', zIndex: '2' }}> Residential Property for sale in Texas </h4>
              </GridItem>
              <GridItem xs={12} sm={3} style={{ textAlign: 'center' }}>
                <h2 style={{ color: '#f41212', fontWeight: 'bold' }}> 220+ </h2>
                <h4 style={{ color: 'white', position: 'relative', zIndex: '2' }}> Residential Property for Rent in Texas </h4>
              </GridItem>
            </GridContainer>
           
          </div>
        </GridContainer>
      </div>      
    </div>
  );
}
