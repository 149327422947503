/*!

=========================================================
* Material Kit PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.8.0";

// pages for this product
import LoginPage from "views/LoginPage/LoginPage.js";
import PresentationPage from "views/PresentationPage/PresentationPage.js";
import SectionsPage from "views/SectionsPage/SectionsPage.js";
import SignUp from "views/SelfAssessment/SelfAssessment";
import Questions from "views/Questions/Questions.js";
import Response from "views/Questions/Response.js";
import Packages from "views/Packages/PackageDetails.js";
import Payment from "views/SelfAssessment/paymentDetails.js";
import Assessment from "views/SelfAssessment/AskAssessment";
import AboutUs from "views/AboutUs/AboutUs.js";


var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/sign-in" component={LoginPage} />
      <Route path="/sections" component={SectionsPage} />
      <Route path="/signup" component={SignUp} />
      <Route path="/question" component={Questions} />
      <Route path="/response" component={Response} />
      <Route path="/packages" component={Packages} />
      <Route path="/about-us" component={AboutUs} />
      <Route path="/assessment" component={Assessment} />
      <Route path="/payment" component={Payment} />
      <Route path="/" component={PresentationPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
